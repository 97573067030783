import React from "react";
import Subscribe from "../subscribe.png";

const NewsLetter = () => {
  return (
    <>

      <main className="pt-5">
        <section className="section-1">
          <div className="section-1-main">
            <div className="section-1-content mt-3">
              <h1 className="section-1-title">
                Get Ahead: Join Our Exclusive Waiting List Today!
              </h1>
              <h5 className="section-1-desc text-dark">
                We're thrilled to have you here. Exciting things are happening
                behind the scenes, and we don't want you to miss out. Be among
                the first to get access to our upcoming product by signing up
                for our waiting list today.
              </h5>
              <h5 className="section-1-desc text-dark">
                By joining our waiting list, you'll be granted exclusive
                benefits, early access, and insider updates. Don't worry, we
                value your privacy, and we'll only use your information to keep
                you informed about our progress and notify you when we're ready
                to launch.{" "}
              </h5>
              <h5 className="section-1-desc text-dark">
                So, why wait? Take this opportunity to secure your spot on our
                waiting list and be part of an innovative journey that will
                revolutionize your work. Sign up now and be one step closer to
                experiencing something truly remarkable.
              </h5>
              <h5 className="section-1-desc text-dark">
                Thank you for your interest and support. We can't wait to embark
                on this exciting adventure with you!
              </h5>

              <script src="https://f.convertkit.com/ckjs/ck.5.js" />
              <form
                action="https://app.convertkit.com/forms/5208381/subscriptions"
                class="seva-form formkit-form section-1-form"
                method="post"
                data-sv-form="5208381"
                data-uid="1bcf019ae5"
                data-format="modal"
                data-version="5"
                data-options='{"settings":{"after_subscribe":{"action":"message","success_message":"Success! Now check your email to confirm your subscription.","redirect_url":""},"analytics":{"google":null,"fathom":null,"facebook":null,"segment":null,"pinterest":null,"sparkloop":null,"googletagmanager":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"powered_by":{"show":true,"url":"https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic"},"recaptcha":{"enabled":false},"return_visitor":{"action":"show","custom_content":""},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}'
                min-width="400 500 600 700 800"
              >
                <input
                  class="formkit-input form-control mb-2 w-100"
                  name="email_address"
                  aria-label="Email Address"
                  placeholder="Email Address"
                  required
                  type="email"
                  style={{ height: "50px" }}
                />

                <button
                  data-element="submit"
                  class="formkit-submit formkit-submit btn btn-primary w-100"
                >
                  <div class="formkit-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span class="text-white fw-bold">
                    Subscribe <i className="fas fa-envelope fa-1x"></i>
                  </span>
                </button>

                <div class="formkit-powered-by-convertkit-container"></div>
              </form>

              <div
                class="formkit-guarantee text-center mt-3"
                data-element="guarantee"
              >
                We won't send you spam. Unsubscribe at any time.
              </div>
            </div>

            <div className="section-1-imgs">
              <div className="section-1-imgs-main">
                <img src={Subscribe} alt="subscribe" />
              </div>
            </div>
          </div>

          <footer>
            <div className="user-content">
              <div className="user-img">
                <a
                  href="https://www.linkedin.com/company/starlaunchtechnology/?viewAsMember=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa-brands fa-linkedin-in fa-2x"
                    style={{ color: " #0072b1" }}
                  ></i>
                </a>
              </div>
              <div className="user-details">
                <h4 className="mt-3">
                  <a
                    href="https://www.linkedin.com/company/starlaunchtechnology/?viewAsMember=true"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Follow Us on LinkedIn
                  </a>
                </h4>
              </div>
            </div>
          </footer>
        </section>
      </main>
    </>
  );
};
export default NewsLetter;
