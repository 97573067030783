import React from "react";
import { Link } from "react-router-dom";
import Desc from "./Desc";
import CTA from "./CTA";
import Hero_bg from "./hero-bg.jpg";
import Key_points from "./Key_points.png";

export default function HeroSection() {
  return (
    <>
      <section
        className="hero-section"
        id="hero"
        style={{ backgroundImage: "url(" + Hero_bg + ")" }}
      >
        <div className="wave">
          <svg
            width="100%"
            height="355px"
            viewBox="0 0 1920 355"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Apple-TV"
                transform="translate(0.000000, -402.000000)"
                fill="#FFFFFF"
              >
                <path
                  d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </svg>
        </div>

        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">
                    Store customer data, track interactions, and automate sales
                    and marketing processes
                  </h1>
                  <p
                    className="mb-5 text-white"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    Don't miss out on the opportunity. Reserve your spot on our
                    waiting list now and be among the first to experience what's
                    coming!
                  </p>
                  <p
                    data-aos="fade-right"
                    data-aos-delay="200"
                    data-aos-offset="-500"
                  >
                    <button className="btn btn-primary">
                      <Link to="/newsletter">
                        <h4 className="text-white text-capitalize fw-bold">
                          Claim your spot!
                        </h4>
                      </Link>
                    </button>
                  </p>
                </div>

                <div className="col-lg-4 iphone-wrap">
                  <img
                    src={Key_points}
                    alt="Key_Points"
                    className="phone-2 h-100 w-100"
                    data-aos="fade-right"
                    data-aos-delay="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Desc />

      <CTA />
    </>
  );
}
