import React from "react";
import { Link } from "react-router-dom";
import informed_decision from "./informed_decision.png";
import Personalization from "./Personalization.png";

export default function Desc() {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 me-auto">
              <h2 className="mb-4">
                Streamline your operations, improve customer satisfaction,
                manage and nurture relationships your customers.
              </h2>
              <p className="mb-4 text-dark">
                Exciting things are on the horizon! Be the first to know by
                signing up on our waiting list. Don't miss out, secure your spot
                today!
              </p>
              <p>
                <button className="btn btn-primary">
                  <Link to="/newsletter">
                    <h6 className="text-white text-capitalize fw-bold">
                      Take the first step!
                    </h6>
                  </Link>
                </button>
              </p>
            </div>
            <div className="col-md-6" data-aos="fade-left">
              <img
                src={informed_decision}
                alt="informed_decision"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 ms-auto order-2">
              <h2 className="mb-4">
                Drive growth by gaining valuable insights into customer behavior
                and preferences
              </h2>
              <p className="mb-4 text-dark">
                Get ahead of the game. Join our waiting list now and gain early
                access to our groundbreaking service. Sign up and be a
                trailblazer!
              </p>
              <p>
                <button className="btn btn-primary">
                  <Link to="/newsletter">
                    <h6 className="text-white text-capitalize fw-bold">
                      Unlock exclusive access!
                    </h6>
                  </Link>
                </button>
              </p>
            </div>
            <div className="col-md-6" data-aos="fade-right">
              <img
                src={Personalization}
                alt="Personalization"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
