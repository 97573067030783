import { Route, BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import HeroSection from "./components/HeroSection";
import NewsLetter from "./components/NewsLetter/NewsLetter";
import ThankYou from "./components/thank-you/ThankYou";
import Footer from "./footer/Footer";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <HeroSection />
              <Footer />
            </>
          }
        />
        <Route exact path="/newsletter" element={<NewsLetter />} />
        <Route exact path="/thank-you" element={<ThankYou />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
