import React from "react";

export default function Footer() {
  return (
    <section class="">
      <footer
        class="text-center text-white"
        style={{ backgroundColor: "#0a4275" }}
      >
        <div class="container p-4 pb-0">
          <section class="">
            <p class="d-flex justify-content-center align-items-center">
              <h5 class="me-3">
                <a
                  href="https://www.linkedin.com/company/starlaunchtechnology/?viewAsMember=true"
                  target="_blank"
                  className="text-white fw-bold"
                  rel="noopener noreferrer"
                >
                  Follow Us On LinkedIn
                </a>
              </h5>
            </p>
          </section>
        </div>

        <div
          class="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          {" "}
          © Starlaunch Technology
        </div>
      </footer>
    </section>
  );
}
