import React from "react";
import { Link } from "react-router-dom";

export default function CTA() {
  return (
    <section class="section cta-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 me-auto text-center text-md-start mb-5 mb-md-0">
            <h3 class="fw-bold text-white">
              Be part of the VIP club! Join our waiting list for exclusive
              perks, insider updates, and priority access. Reserve your spot
              today!
            </h3>
          </div>
          <div class="col-md-5 text-center text-md-end">
            <p>
              <button class="btn d-inline-flex align-items-center">
                <i class="bx bxl-apple"></i>
                <span>
                  <Link to="/newsletter">
                    <h6 className="text-white text-capitalize fw-bold">
                      Discover the possibilities!
                    </h6>
                  </Link>
                </span>
              </button>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
