import React from "react";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export default function ThankYou() {
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="col-md-4">
        <div className="border border-3 border-success"></div>
        <div className="card  bg-white shadow p-5">
          <div className="mb-4 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-success bi bi-check-circle"
              width="75"
              height="75"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <div className="text-center">
            <h1 className="fw-bold">We've sent you a confirmation email. Please check your email</h1>
            <h6>
              Thank you for subscribing to our waiting list! Your support means
              the world to us.
            </h6>
            <p>
              We would love it if you could share your excitement about our
              product with your friends on social media. Together, we can create
              a buzz and make a difference.
            </p>
            <p>
              Thank you again for joining our waiting list. Stay tuned for
              updates and exciting news!
            </p>

            <section className="text-center border-top border-bottom py-4 mb-4">
              <ul className="mr-3">
                <li>
                  <LinkedinShareButton
                    url="dennismbugua.co.ke"
                    title="The best thing for you is me!"
                    className="mr-2"
                  >
                    <LinkedinIcon size={40} round={true} />
                  </LinkedinShareButton>
                </li>

                <li>
                  <TwitterShareButton
                    url="dennismbugua.co.ke"
                    title="The best thing for you is me!"
                    via={"dennismbugua_"}
                    className="mr-2"
                  >
                    <TwitterIcon size={40} round={true} />
                  </TwitterShareButton>
                </li>

                <li>
                  <FacebookShareButton
                    url="dennismbugua.co.ke"
                    title="The best thing for you is me!"
                    className="mr-2"
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                </li>

                <li>
                  <WhatsappShareButton
                    url="dennismbugua.co.ke"
                    title="The best thing for you is me!"
                    separator={" "}
                    className="mr-2"
                  >
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                </li>

                <li>
                  <RedditShareButton
                    url="dennismbugua.co.ke"
                    title="The best thing for you is me!"
                    className="mr-2"
                  >
                    <RedditIcon size={40} round={true} />
                  </RedditShareButton>
                </li>

                <li>
                  <TelegramShareButton
                    url="dennismbugua.co.ke"
                    title="The best thing for you is me!"
                    className="mr-2"
                  >
                    <TelegramIcon size={40} round={true} />
                  </TelegramShareButton>
                </li>
              </ul>
            </section>

            <button
              className="btn btn-block"
              style={{ backgroundColor: "#CC7722" }}
            >
              <Link to="/">
                <h6 className="fw-bold text-white">Back Home</h6>
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
