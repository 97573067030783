import React from "react";
import { Link } from "react-router-dom";

export default function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div className="container">
        <Link className="navbar-brand mb-0 h1 me-2 text-dark fw-bold" to="/">
          Starlaunch Technology
        </Link>
      </div>
    </nav>
  );
}
